import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Globals } from '../../globals';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../../lib/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  tab=1;
  uname:string="";
  passwd:string="";

  remember_connected:boolean=false;

  @Input()
  redirectAfterLoginEvent:boolean=true;

  @Input()
  redirectAfterLogin:string="";

  @Input()
  useStoredAccess=true;

  @Input()
  redirectToLoginPage=true;

  @Output()
  onSuccess:EventEmitter<boolean>=new EventEmitter<boolean>();

  get softwarename():string {
      return Globals.config.softwarename;
  }

  get softwareicon():string {
    return Globals.config.softwareicon;
  }

  get softwarebackground():string {
    return Globals.config.softwarebackground;
  }

  get softwarecolor():string {
    return Globals.config.softwarecolor;
  }

  get copyright(){
   return Globals.config.copyright;
 }

 public loginForm: FormGroup= new FormGroup(
  {    
    username: new FormControl(''),    
    password: new FormControl(''),
    remember_connected:new FormControl('')
  });


  public resetForm: FormGroup= new FormGroup(
    {    
      username: new FormControl(''),    
      newpassword: new FormControl(''),
      pin:new FormControl('')

    }
  );

  errormessage="";
  successmessage="";
  onerror=false;
  onsuccess=false;

  constructor(
    private _fb: FormBuilder,
    private userService:UserService

  ) { }

  ngOnInit(): void {
    
  }

  onLogin(){
    Globals.access.onLogin(this.loginForm.controls['username'].value,this.loginForm.controls['password'].value,this.remember_connected?"1":"0",this.redirectAfterLogin,this.useStoredAccess,this.redirectToLoginPage,this.redirectAfterLoginEvent);
  }

  login(){
    Globals.access.login(this.loginForm.controls['username'].value,this.loginForm.controls['password'].value,(success)=>{
      if(success){
        this.onSuccess.emit(true);
        this['modalWindow'].close("success");

        if(this.remember_connected){
          
          localStorage.setItem(Globals.access.prefix+"_username",this.uname);
          localStorage.setItem(Globals.access.prefix+"_password",this.passwd);
        }
        this.onLogin();
      }
    });
  }

  requestResetPassword(){
    
    
    this.userService.requestResetPassword(this.loginForm.controls['username'].value).subscribe((result:any)=>{
      
        
        if(result.error){
          if(result.error_code==21){
            this.errormessage="Utente non trovato!"
          }
          
          this.onerror=true;
          setTimeout(()=>{
            this.onerror=false;
          },2000);
        }else{
          this.tab=3;
        }
      },(error:any)=>{
      }
    );
  }

  resetPassword(){
    
   

  }
   

}
