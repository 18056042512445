import { Component, Input, OnInit } from '@angular/core';
import { json } from 'mathjs';
import { TakephotoComponent } from 'projects/core/src/common/takephoto/takephoto.component';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { Image } from '../../../db/image';
import { AddressComponent } from '../../../views/address/address.component';
import { AddressDetailsComponent } from '../../../views/address/address-details/address-details.component';
import { UploadfileComponent } from '../../uploadfile/uploadfile.component';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})
export class FieldComponent implements OnInit {

  @Input()
  field:any=null;

  @Input()
  fields:any=[];

  @Input()
  record:any; //record necessario per la parserizzazione delle keyword 

  @Input()
  disabled:boolean=false;
  
  constructor(private uploadService:UploadService) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(){
    if(this.field.value=="" || this.field.value==null || this.field.value==undefined){
      if (this.field.defaultvalue!=""){
        this.field.value=this.field.defaultvalue;
      }
    }

    if(this.field.type=="select"){
      if(!Array.isArray(this.field.params)){
        if(this.field.params!=null)
          this.field.params=this.field.params.split(";");
        else
          this.field.params=[];
      }
    }

    if(this.field.type=="address"){
      console.log(this.field);
      if(this.field.value==null){
        this.field.value={
          "name":"",
          "id":0
        }
      }
      
    }

    if(this.field.type=="table"){
      if(!Array.isArray(this.field.params)){
        try{
          this.field.params=JSON.parse(this.field.params)
        }catch{
          this.field.params=[];
        }
        
      }

      if(this.field.value==null){
        this.field.value=[];
      }else{

      
        if(!Array.isArray(this.field.value)){
          try{
            this.field.value=JSON.parse(this.field.value)
          }catch{
            this.field.value=[];
          }
        }
      }
    }
  }

  DateDefaultValue(field){
    let dv=field.defaultvalue;
    for(let i=0;i<this.fields.length;i++){
      let f=this.fields[i];
      
      dv=dv.replaceAll("{{"+f.name+"}}",f.value);
      
    }
    
    field.value=new Date(eval(dv)).toMysqlDate();

    
  }

  getBasePath(path){
    if(path)
      return Globals.config.serverUrl+"/"+path;
    return "";
  }

  parseKeyword(value){
    if(this.record)
      return Helper.replaceKeyword(value,this.record);
    else
      return value;
  }

  downloadFile(relative_path){
    window.open( this.getBasePath(relative_path),"_blank");
  }

  /*
  uploadFile(){

    try{
      if(!Array.isArray(this.field.params)){
        this.field.params=JSON.parse(this.field.params);
      }
    }catch{
      this.field.params=[];
    }

    Globals.modal.showModal(UploadfileComponent,[
      {"name":"record","value":this.record},
      {"name":"params","value":this.field.params},

    ],(instance)=>{

      if(instance){
        this.field.value=instance['filename'];
      }
    },"md");
  }
    */

  pathImage(relative_path){
    if(relative_path==""){
      return "assets/placeholder_photo.jpg";
    }else{
      return this.getBasePath(relative_path);
    }
  }

  addFile(field){
    Globals.modal.showModal(UploadfileComponent,[
      {"name":"record","value":this.record},
      {"name":"params","value":field.params},

    ],(instance)=>{
      
      if(instance){

        if(!Array.isArray(field.value))
          field.value=[];
        if(instance['filename'])
          field.value.push(instance['filename']);
        
      }
    },"md");
  }


  addPhoto(field){
    Globals.modal.showModal(TakephotoComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance){
        this.uploadService.uploadImage64(instance.imageCaptured,"ads",(result)=>{
          if(!Array.isArray(field.value))
            field.value=[];
          
          field.value.push(result);
          
        },true);
      }
    });
  }
  takePhoto(field){
    Globals.modal.showModal(TakephotoComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance){
        this.uploadService.uploadImage64(instance.imageCaptured,"ads",(result)=>{
          field.value=result;
        },true);
      }
    });
  }


  isPhotoMultiple(field){
    if(!Array.isArray(field.params)){
      try{
        field.params=JSON.parse(field.params);
   
      }catch{
        field.params=[];
      }
    }


    if(field.params.length>0){
      for(let p of field.params){
        if(p.name=="multiple"){
          return p.value;
        }
      }
    }
    return false;
  }
  parseMultiplePhoto(value:any){
    if(!Array.isArray(value)){
      try{
        return JSON.parse(value);
  
      }catch{
        return [];
      }
    }

    return value;
  }
  deletePhoto(field,imgPath){
    Globals.modal.showConfirm("","Confermi di voler eliminare la foto?",()=>{  
      if(!Array.isArray(field.value)){
        field.value=JSON.parse(field.value);
      }

      for(let i=0;i<field.value.length;i++){
        if(field.value[i]==imgPath){
          field.value.splice(i,1);
          return;
        }
      }
    },"Sì. Elimina","No. Annulla","danger");

  }



  isFileMultiple(field){
    if(!Array.isArray(field.params)){
      try{
        field.params=JSON.parse(field.params);
   
      }catch{
        field.params=[];
      }
    }


    if(field.params.length>0){
      for(let p of field.params){
        if(p.name=="multiple"){
          return p.value;
        }
      }
    }
    return false;
  }
  parseMultipleFile(value:any){
    if(!Array.isArray(value)){
      try{
        return JSON.parse(value);
  
      }catch{
        return [];
      }
    }

    return value;
  }
  deleteFile(field,imgPath){
    Globals.modal.showConfirm("","Confermi di voler eliminare il file?",()=>{  
      if(!Array.isArray(field.value)){
        field.value=JSON.parse(field.value);
      }

      for(let i=0;i<field.value.length;i++){
        if(field.value[i]==imgPath){
          field.value.splice(i,1);
          return;
        }
      }
    },"Sì. Elimina","No. Annulla","danger");

  }


  calculateFormula(formula,field){

    
    //sostituisci nella formula i valori degli altri campi
    for(let f of this.fields){
      formula=formula.replaceAll("{{"+f.name+"}}",f.value);
    }

  
    
    const result=eval(formula);
    field.value=result;
    return result;
    

  }

  selectAddress(field){

    if(!Array.isArray(field.params)){
      try{
        field.params=JSON.parse(field.params);
      }catch{
        field.params=[];
      }
    }
    var id_type=0;
    if(Array.isArray(field.params)){
      for(let p of field.params){
        if(p.name=="id_type")
          id_type=parseInt(p.value);
      }
    }
      

    Globals.modal.showModal(AddressComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_type","value":id_type},
      
    ],(instance)=>{
      if(instance!=null){
        this.field.value={"name":instance['recordSelected'].name,"id":instance['recordSelected'].id};
      }
    })
  }
  openAddress(id){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":id}
    ],(instance)=>{
      
    })
  }


  getFileTypeIcon(extension:String){
    switch(extension.toLowerCase()){
      case "png":
      case "jpg":
      case "bmp":
      case "tif":
      case "tiff":
      case "jpeg":
      case "gif":
        return "fa-image";
        
      case "kml":
      case "geojson":
      case "kmz":
        return "fa-map";
      case "doc":
        return "fa-file-word";
      case "ppt":
          return "fa-file-powerpoint";
      case "pdf":
          return "fa-file-pdf";
     case "xls":
      case "xlsx":
        return "fa-file-excel"
      default:
        return "fa-file"
    }
  }

  getExtension(path){
      if(path){
        const parts=path.split(".");
        if(parts.length>0)
          return parts[parts.length-1];
        else
          return "";
      }
      return "";
  }

  getFilename(path){

    if(path){
      const parts=path.split("/");
      if(parts.length>0)
        return parts[parts.length-1];
      else
        return "";
    }
    return "";
 
  }

  addRowTable(table,header){

    var row={fields:[],value:""};
    for(let h of header){
      var field={};
      Object.assign(field,h);
      row.fields.push(field);
    }


    
    if(table==null)
      table=[];
    table.push(row);

  }

  deleteRowTable(table,row){
    for(let i=0;i<table.length;i++){
      if(table[i]==row){
        table.splice(i,1);
        return;
      }
    }
  }

}
