import { Injectable } from '@angular/core';
import { Activities } from '../db/activities';
import {CashflowService} from './cashflow.service'
import { WSService } from 'projects/core/src/include/service';
import { CustomfieldvaluesService } from 'projects/core/src/lib/customfieldvalues.service';
import { Globals } from 'projects/core/src/globals';
//import { ActivitiesModule } from '../views/activities/activities.module';
import { CashFlowDetailsComponent } from '../views/cash-flow/cash-flow-details/cash-flow-details.component';
import { CustomFieldValues, Filter, FilterAdvanced, Ordering } from 'projects/core/src/include/structures';
import { Observable } from 'rxjs';
import { NewformComponent } from '../modules/newform/newform.component';
import { FormsValueService } from './formsvalue.service';
import { TypesService } from './types.service';
import { ConfirmMissingValuesComponent } from '../views/activities/missing-values/confirm-missing-values/confirm-missing-values.component';
import { UserActionsComponent } from '../views/activities/user-actions/user-actions.component';
import { ActivitiesBatchComponent } from '../views/activities/batch/batch.component';
import { Helper } from 'projects/core/src/helper';
@Injectable({
  providedIn: 'root'
})

export class ActivitiesService extends WSService<Activities> {

  createCashflowAfterSave=true;

  constructor(
    private customfieldvaluesService:CustomfieldvaluesService,
    private cashflowService:CashflowService,
    private typeService:TypesService
    ) {super("activities") }

    

  save(item:Activities,return_id:Function,checkdeadlinesandactions=true,showAfterMessage=true){
    super.save(item,(r_id)=>{
      return_id(r_id);
      if(Globals.events['savingActivityEvent'])
        Globals.events['savingActivityEvent'].emit();
      
      if(item.phase==3){


        //verifica se è un'attività che necessità la creazione di un'altra attività successiva
        let autocreate_activities=Globals.parameters.get("activities").getParam("autocreate_activities");
        if(autocreate_activities.length>0){
          let list_new_activities=[];
          for(let i=0;i<autocreate_activities.length;i++){
            if(autocreate_activities[i]['id_type_old']==item.id_type){
              let activity:Activities=new Activities();
              activity.id=0;
              activity.id_table=item.id_table;
              activity.table=item.table;
              activity.id_address=item.id_address;
              activity.address=item.address;
              let date=new Date(item.date);
              let days=parseInt(Helper.replaceKeyword(autocreate_activities[i]['date_new'],item));
              date.setDate(date.getDate()+days);
              activity.date=date.toMysqlDate();
              activity.city=item.city;
              activity.zip=item.zip;
              activity.latitude=item.latitude;
              activity.longitude=item.longitude;
              activity.id_type=autocreate_activities[i]['id_type_new'];
              activity.id_user=1;
              

              activity['activity_from']=item;

              activity['post_save']=autocreate_activities[i]['actions'];
              list_new_activities.push(activity);


            }
          }

          if(list_new_activities.length>0){
            Globals.modal.showModal(ActivitiesBatchComponent,[
              {"name":"mode","value":"modal"},
              {"name":"list","value":list_new_activities}
            ],()=>{

            })
          }
        }


        //replica il valore dei customfield (se presente)
        for(let i=0;i<item.customfields.length;i++){ 
          if(item.customfields[i].replicate_to>0){
              let cfv={} as CustomFieldValues;
              this.customfieldvaluesService.deleteSQL("id_customfield="+item.customfields[i].replicate_to+" AND id_table="+item.id_table+ " AND `table`='"+item.table+"'").subscribe(()=>{
                cfv.id=0;
                cfv.id_customfield=item.customfields[i].replicate_to;
                cfv.table=item.table;
                cfv.id_table=item.id_table;
                cfv.value=item.customfields[i].value;
                this.customfieldvaluesService.save(cfv,()=>{

                });
              })
              
          } 
        }

        //registra i dati mancanti
        let missingFields=[];
        if(item.missingValues.length>0){
          for(let m of item.missingValues){
              if(m.value!="" && m.value!=null && m.value!=undefined)
                missingFields.push(m.label+": "+m.value);
            }
          
          if(missingFields.length>0){
            this.getItem(item.id).subscribe((item_loaded)=>{
              Globals.modal.showModal(ConfirmMissingValuesComponent,[
                {"name":"mode","value":"modal"},
                {"name":"list","value":item.missingValues},
                {"name":"item","value":item_loaded}
              ],()=>{
    
              });
            });

            
            
            /*if(confirm("Sono stati acquisiti i seguenti dati mancanti:\n"+missingFields.join("\n")+"\n\nAggiornarli i dati mancanti nelle schede del database ?")){
              this.updateMissingValues(item.missingValues).subscribe((result)=>{
                
              });
              
              
            }*/

          }
        }

        //registra le azioni svolte dall'operatore
        if(typeof(item.user_action)!='object'){
          try{
            item.user_action=JSON.parse(item.user_action);
          }catch{
            item.user_action={};
          }
        }

        if(item.user_action && item.user_action.action && item.user_action.action!=""){
          if(item.user_action.action =="substitution" || item.user_action.action =="retired"){
          
            Globals.modal.showModal(UserActionsComponent,[
              {"name":"mode","value":"modal"},
              {"name":"record","value":item}
            ],()=>{

            });
          }
        }

        //registra il pagamento
        if(this.createCashflowAfterSave){


        //controlla se vi sono documenti collegati e se questi non hanno registrato nessun pagamento
        let noCashFlowDocuments=true;

        //nel caso di collegamento verso un'altra attività, attribuisci i documenti a questa attività
        if(item.table=='activities'){
          item.documents=item.reference.documents;
          
        }

        if(item.documents && item.documents.length>0){
          let paid=0;
          for(let d of item.documents){

            if(d.paid>=item.paid){
              noCashFlowDocuments=false;
            }
            /*
            if(d.cashflow.length>0){

              //verifica se i pagamenti hanno un versato pari a zero e/o inferiore a quanto versato in questa attività
              
              for(let c of d.cashflow){
                paid=paid+c.paid;
              }

              if(paid>=item.paid){
                noCashFlowDocuments=false;
              }

             
            }
              */
              
          }

        }


        if(
          (item.amount>0 || item.paid>0 || item.paid_description!="")  && 
          (item.id_cashflow==0 || item.id_cashflow==undefined) && 
          item.enable_accounting && 
          (
            item.documents==null || item.documents.length==0 || (item.documents.length>0 && noCashFlowDocuments)) 
          ){
          let address=this.getAddressFromReference(item);
          let id_address=0;
          if(address)
            id_address=address['id']
            if(item.documents && item.documents.length>0){
              this.openCashFlowDetail(item,id_address,address,item.documents[0],"documents");
            }else{
              this.openCashFlowDetail(item,id_address,address,item);
            }
            
          
        }
        }

      }

      if(item.phase==2){
        //apri la compilazione di un modulo (se presente)
        try{

          //verifica se il record ha già un form compilato
          if(item.id_form==0){
            this.typeService.getItem(item.id_type).subscribe((type)=>{
              let params=JSON.parse(type.params);
              if(params.id_form){

                
                //apri il modulo corrispondente
                if(confirm("Compilare ora il modulo per l'attività corrente?")){
                  let formsService:FormsValueService=new FormsValueService();
      
                  formsService.showNewForm(item.reference,item.table,(id_form)=>{
                      item.id_form=id_form;
                      this.save(item,()=>{

                      });
                  },params.id_form);
                }
              }
            });
          }
       
        }catch{

        }

        

        

      }

    },checkdeadlinesandactions,showAfterMessage);

    
  }

  openCashFlowDetail(item,id_address,address,reference,table_reference="activities"){
    Globals.modal.showModal(CashFlowDetailsComponent,
      [
        {"name":"mode","value":"modal"},
        {"name":"date","value":item.date},
        {"name":"id_address","value":id_address},
        {"name":"amount","value":item.amount},
        {"name":"paid","value":item.paid},
        {"name":"tax","value":item.tax},
        {"name":"description","value":(item.type.name?item.type.name:item.type)+" "+item.description?item.description:""+" "+item.paid_description},
        {"name":"id_user","value":item.id_user},
        {"name":"table","value":table_reference},
        {"name":"id_table","value":reference.id},
        {"name":"reference","value":reference},
        {"name":"methodpayment","value":item.method_payment},
        {"name":"addressItem","value":address},
      
        
      ],(instance)=>{
      if(instance!=null){

        this.cashflowService.save(instance['record'],(id)=>{
          
          item.id_cashflow=id;
          var item2send=item;
          if(Globals.ws.api_version=="v2")
            item2send={"values":item}

          Globals.ws.send("activities","save",item2send,[],()=>{});
        });
      }else{
        if(!confirm("Nessun movimento registrato. Procedere ugualmente?")){
          this.openCashFlowDetail(item,id_address,address,reference,table_reference);
        }
      }
    });
  }
  getAddressFromReference(item:Activities){
    switch(item.table){
      case "addresses":
        return item.reference;  
      case "shops":
        return item.reference['addressItem'];
      case "installations":
        return item.reference['shop']['addressItem'];
      case "contractsinstallments":
          return item.reference['shop']['addressItem'];
      case "contracts":
          return item.reference['shop']['addressItem'];
      case "activities":
          return this.getAddressFromReference(item.reference);
         
    }
  }

  updateMissingValues(values):Observable<any>{
    return Globals.ws.requestUrl("activities","updateMissingValues",[JSON.stringify(values)]);
 
  }

  getTotals(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[]):Observable<any>{
    return Globals.ws.requestUrl("activities","getTotals",this.getSQLWhere(filters,ordering,advancedFilter));
  }
   /* 
  saveMultiple(items,oncomplete){
    return Globals.ws.send("activities","saveMultiple",items,[],oncomplete);
  }
*/

  checkItems(where):Observable<any>{
    return Globals.ws.requestUrl("activities","getItems",[where]);
  }


  getAnalytics(from,to,phase):Observable<any>{
    return Globals.ws.requestUrl("activities","getAnalytics",[from,to,phase]);
  }

  saveGPSposition(id_user,latitude,longitude):Observable<any>{
    return Globals.ws.requestUrl("activities","saveGPSposition",[id_user,latitude,longitude]);
    
  }

  getGPSposition():Observable<any>{
    return Globals.ws.requestUrl("activities","getGPSposition",["true"]);
    
  }

  executeUserAction(user_action:any):Observable<any>{
    return Globals.ws.requestUrl("activities","executeUserAction",[JSON.stringify(user_action)]);
  }

  getActivitiesByUser(id_user,date,phase):Observable<any>{
    return Globals.ws.requestUrl("activities","getActivitiesByUser",[id_user,date,phase]);
  
  }

  getLogs(id_activity):Observable<any>{
    return Globals.ws.requestUrl("activities","getLogs",[id_activity]);
  }

  addLog(log:any,oncomplete){
    var item2send=log;
    if(Globals.ws.api_version=="v2")
      item2send={"values":log}

    Globals.ws.send("activities","addLog",item2send,[],()=>{
      oncomplete();
    });
  }

  getItemsGrouped(from,to,grouptype="day",table="",join="",where_join=""):Observable<any>{
    return Globals.ws.requestUrl("activities","getItemsGrouped",[from,to,grouptype,table,join,where_join]);
 
  }

  savePlanner(planner:any,oncomplete){

    var item2send=planner;
    if(Globals.ws.api_version=="v2")
      item2send={"values":planner}

    Globals.ws.send("activities","savePlanner",item2send,[],()=>{
      oncomplete();
    });
  }
}

