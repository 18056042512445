


export interface ISettingWindow  {}
export class CSettingWindow{
    closeModal(){
        this['modalWindow'].close();
    }
}

export namespace ISettingWindow {
    type Constructor<T> = {
      new(...args: any[]): T;
      readonly prototype: T;
    }
    const implementations: Constructor<ISettingWindow>[] = [];
    export function GetImplementations(): Constructor<ISettingWindow>[] {
      return implementations;
    }
    export function register<T extends Constructor<ISettingWindow>>(ctor: T) {
      implementations.push(ctor);
      return ctor;
    }

    
  }